import { Layout, Skeleton } from 'antd';
import css from './styles.module.css';

const { Header, Sider, Content } = Layout;

export type Props = {
  empty?: boolean,
  children?: React.ReactNode
}

const MainLayout = ({
  empty = false,
  children
}: Props): JSX.Element => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        theme="light"
        className={css['ant-layout-sider-zero-width-trigger']}
        collapsedWidth={typeof window.orientation !== 'undefined' ? 0 : 70}
        collapsed={typeof window.orientation !== 'undefined' ? true : false}
      >
        <div className={css.noodle_circle_logo}/>
        <Skeleton.Input active style={{margin:'15px 0 0 15px', height:35, width:170}} />
        <Skeleton.Input active style={{margin:'15px 0 0 15px', height:35, width:170}} />
        <Skeleton.Input active style={{margin:'15px 0 0 15px', height:35, width:170}} />
      </Sider>
      <Layout className={css.site_layout}>
        {!empty && <Header className={css.site_layout_background} style={{ padding: 0 }}>
          <div className={css.skeleton_header_background}>
            <Skeleton.Avatar active size={40} style={{marginTop:'13px'}} shape="circle" />
            <Skeleton.Input active style={{height:35, margin:'15px 0 0 4px',width:140}} />
          </div>
        </Header>}
        <Content style={{ margin: '16px 16px' }}>
          { children }
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
