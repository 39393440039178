import React, { useContext } from 'react';
import { Auth } from 'aws-amplify';
import { fetchGQL } from 'utils/fetch';
import { useHistory } from 'react-router-dom';
import AuthContext from 'context/auth/context';
import { RESET_USER_PASSWORD } from './queries';
import noodleLogo from 'assets/images/noodle-logo.svg';
import { Row, Col, Form, Input, Card, Button, Layout, Progress, Modal } from 'antd';
import css from './styles.module.css';

interface ForgotPasswordProps {
  user?: any;
  forgotPasssword?: boolean;
}

interface StringContent {
  [key: string]: string;
}

const buttonLabel = (val: boolean | undefined) => (val ? 'Recover Password' : 'Set Password');
const tittleLabel = (val: boolean | undefined) =>
  val ? 'FORGOT YOUR PASSWORD?' : 'SET YOUR PASSWORD';

const recoveryDescription =
  'Password must be 8 characters long and include at least 1 lower case, 1 upper case, 1 number, and 1 symbol.';
const resetDescription = 'Use the form below to recover it.';

const SubmitButton = ({ loading, forgotPasssword }: any): JSX.Element => (
  <Button
    disabled={loading}
    className={css.password_button}
    type='primary'
    size='large'
    shape='round'
    block
    htmlType='submit'
  >
    {buttonLabel(forgotPasssword)}
  </Button>
);

const RecoveryForm = ({ loading }: any): JSX.Element => (
  <>
    <Form.Item name='newPassword' label='Password:' rules={[{ required: true }]}>
      <Input placeholder='Enter Your New Password' type='password' disabled={loading} />
    </Form.Item>
    <Form.Item name='confirmPassword' label='Confirm Password:' rules={[{ required: true }]}>
      <Input placeholder='Confirm Your Password' type='password' disabled={loading} />
    </Form.Item>
  </>
);

const RequestForm = ({ loading }: any): JSX.Element => (
  <Form.Item name='email' label='Email:' rules={[{ required: true, type: 'email' }]}>
    <Input placeholder='Email here...' disabled={loading} />
  </Form.Item>
);

const errorModal = (content: string) => Modal.error({ title: 'Error', content });

const getMessage = (originalMessage: string) => {
  const newError = originalMessage.includes('not long')
    ? `${originalMessage}, needs to be 8 characters long at least.`
    : originalMessage;
  errorModal(newError);
};

const ForgotPassword = ({ user, forgotPasssword }: ForgotPasswordProps): JSX.Element => {
  const [code, setCode] = React.useState<string>();
  const [email, setEmail] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);
  const auth = useContext(AuthContext);
  const history = useHistory();
  const [form] = Form.useForm();

  React.useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const codeValue = urlParams.get('code');
    const emailValue = urlParams.get('email');
    setCode(codeValue!);
    setEmail(emailValue!);
  }, []);

  const successModal = (content: string, email: string, password: string) => {
    Modal.success({
      content,
      onOk() {
        afterSuccessSubmit({ email, password });
      },
    });
  };

  const afterSuccessSubmit = async ({ email, password }: StringContent) => {
    try {
      const user = await Auth.signIn(email!, password);
      auth.setUser(user.attributes);
      auth.setStatus(true);
      history.push('/');
    } catch (error: any) {
      errorModal('Failed to sign in');
    }
  };

  const onSubmit = async (obj: any) => {
    setLoading(true);
    if (obj.newPassword !== obj.confirmPassword) {
      errorModal("Passwords don't match");
      return setLoading(false);
    }
    if (user) {
      try {
        const setNewPassword = await Auth.completeNewPassword(user, obj.newPassword);
        if (
          setNewPassword.authenticationFlowType === 'USER_SRP_AUTH' ||
          setNewPassword.authenticationFlowType === 'USER_PASSWORD_AUTH'
        ) {
          successModal(
            'Successfully updated password',
            user.challengeParam.userAttributes.email,
            obj.newPassword,
          );
        }
      } catch (error: any) {
        getMessage(error.message);
      }
    }
    if (code && email) {
      try {
        const setPasswordWithCode = await Auth.forgotPasswordSubmit(email, code, obj.newPassword);
        if (setPasswordWithCode === undefined) {
          successModal('Successfully updated password', email, obj.newPassword);
        }
      } catch (error: any) {
        getMessage(error.message);
      }
    }
    if (forgotPasssword) {
      try {
        const sendEmailValue = await fetchGQL('post', RESET_USER_PASSWORD, {
          user: { email: obj.email },
        });
        if (sendEmailValue) {
          Modal.success({
            content: 'You should receive an email shortly if this account exists',
            onOk() {
              window.location.reload();
            },
          });
        }
      } catch (error: any) {
        Modal.success({
          content: 'You should receive an email shortly if this account exists',
          onOk() {
            window.location.reload();
          },
        });
      }
    }
    setLoading(false);
  };

  return (
    <Layout className={css.bg_password_page}>
      <Row className={css.generic_flex_fit}>
        <Col xs={2} sm={5} md={7} lg={8} xl={9} xxl={9}></Col>
        <Col xs={20} sm={14} md={10} lg={8} xl={6} xxl={6} style={{ textAlign: 'center' }}>
          <img src={noodleLogo} alt='Noodle-Logo' style={{ width: 150, marginBottom: 24 }} />
          <Card className={css.password_card}>
            {loading && (
              <Progress
                status={loading ? 'active' : 'normal'}
                size='small'
                strokeWidth={4}
                style={{ position: 'absolute', top: -9, left: 0, display: 'flex' }}
                percent={100}
                showInfo={false}
              />
            )}
            <div className={css.password_title}>{tittleLabel(forgotPasssword)}</div>
            <div className={css.password_description}>
              {forgotPasssword ? resetDescription : recoveryDescription}
            </div>
            <Form layout='vertical' form={form} name='control-hooks' onFinish={onSubmit}>
              {forgotPasssword ? <RequestForm /> : <RecoveryForm loading={loading} />}
              <Form.Item style={{ textAlign: 'center' }}>
                <SubmitButton loading={loading} forgotPasssword={forgotPasssword} />
              </Form.Item>
            </Form>
          </Card>
          <div style={{ paddingTop: '10px', fontSize: '12px', color: '#58585b' }}>
            © {new Date().getFullYear()} Noodle. All rights reserved.
          </div>
        </Col>
        <Col xs={2} sm={5} md={7} lg={8} xl={9} xxl={9}></Col>
      </Row>
    </Layout>
  );
};

export default ForgotPassword;
