import React from 'react';

export type MainContextType = {
    datamartClient: any;
}

const MainContext = React.createContext<MainContextType>({
    datamartClient: undefined
});

export default MainContext;