import { useContext, useEffect, useRef, useState } from 'react';
import { Button, Input, Empty, Modal } from 'antd';
import { SendOutlined, LoadingOutlined } from '@ant-design/icons';
import { useAgentsContext } from './../../context/AgentsContext';

import axios from 'axios';
import Markdown from 'react-markdown';
import { useHistory } from 'react-router-dom';
// import remarkGfm from 'remark-gfm';

import css from './styles.module.css';
import { AIInquiryChatContext } from 'components/AIInquiryChat';
import { SelectedFilters } from 'hooks/useFunctionalAreaFilters';
import { ChatHistoryLocalStorage, ChatProps, Messages } from './types';

function randid(length: number): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

function clearChatHistoryFromLocalStorage(agentIdentifier: string) {
    let chatHistory: ChatHistoryLocalStorage = JSON.parse(localStorage.getItem('chatHistory') || '{}');

    if(!chatHistory){
        return;
    }

    delete chatHistory[agentIdentifier];
    localStorage.setItem('chatHistory', JSON.stringify(chatHistory));
}

function getChatHistoryKeyFromLocalStorage(agentIdentifier: string): string {
    let chatHistory: ChatHistoryLocalStorage = JSON.parse(localStorage.getItem('chatHistory') || '{}');
    if (!chatHistory[agentIdentifier]) {
        chatHistory[agentIdentifier] = randid(32);
        localStorage.setItem('chatHistory', JSON.stringify(chatHistory));
        return chatHistory[agentIdentifier];
    }

    return chatHistory[agentIdentifier];
}

function getWebLocationDescription(webLocation: string): string {
    switch (webLocation) {
        case '/marketing':
            return "Marketing Functional Area Page";
        case '/enrollment':
            return "Enrollment Functional Area Page";
        case '/home':
            return "Homepage Cards";
        default:
            return 'Unknown Web Location';
    }
}

function stringifyFilters(filters: SelectedFilters | undefined): string{
    return filters ? JSON.stringify(filters) : '';
}

export const Chat = ({ isGlobalChat, defaultQuestion }: ChatProps) => {
    const { pageFilters } = useContext(AIInquiryChatContext);    
    const {session, agent, clearChat, setClearChat} = useAgentsContext();
    const [isLoading, setIsLoading] = useState(true);
    const [isAgentResponding, setIsAgentResponding] = useState(false);
    const [userMessage, setUserMessage] = useState('');
    const [messages, setMessages] = useState<Messages>([]);
    const [hasMessages, setHasMessages] = useState(false);
    const [waitForMessage, setWaitForMessage] = useState(false);
    const msgEndRef = useRef<HTMLDivElement | null>(null);
    const history = useHistory();
    const humanMessageType = 'HumanMessage';
    const agentMessageType = 'AIMessage';
    const agentId = isGlobalChat ? "ai_inquiry_chat" : agent.identifier;

    const scrollToBottom = () => {
        if (msgEndRef.current?.scrollIntoView){
            msgEndRef?.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const apiRequest = (url_path: string = '', additional_params: object = {}) => {
        const base_url = process.env.REACT_APP_AGENTS_API_URL+url_path;
        const headers = {
            'Authorization': session.accessToken,
            'x-access-token': session.idToken,
            'Content-Type': 'application/json',
        }

        const session_id = getChatHistoryKeyFromLocalStorage(agentId);

        let params: {[key: string]: any} = {
            session_id,
            web_location: history.location.pathname,
        };
        
        if (url_path.includes('history') || !isGlobalChat) {
            params.agent_identifier = agent.identifier;
        } else {
            params.web_location_description = getWebLocationDescription(history.location.pathname);
            params.current_filters = stringifyFilters(pageFilters);
        }

        return axios.post(base_url, {...params, ...additional_params}, {
            headers: headers
        });
    }

    const apiSendMessage = (message: string) => {
        let url = '/chat';

        if (isGlobalChat) {
            url = '/nmanage/chat';
        }

        return apiRequest(url, {
            message: message
        });
    }
    
    const apiRetrieveMessageHistory = () => {
        let url = '/chat/history';

        if (isGlobalChat) {
            url = '/nmanage/chat/history';
        }

        return apiRequest(url);
    }

    const isSendMessageBlocked = () => {
        return isLoading || isAgentResponding || userMessage.length <= 0// || !pageFilters;
    }

    const sendMessage = (defaultMessage?: string|any) => {
        if (!defaultMessage && isSendMessageBlocked()) return false;
        const storedMessage = defaultMessage ? defaultMessage : userMessage;
        setUserMessage('');

        // Immediately locally update the sent message
        messages.push({
            type: humanMessageType,
            content: storedMessage,
        });
        setMessages(messages);
        setHasMessages(true);
        setIsAgentResponding(true);

        apiSendMessage(storedMessage).then((response) => {
            if (response.data.response) {
                let newMessages = [...messages];
                newMessages.push({
                    type: agentMessageType,
                    content: response.data.response,
                });
                setMessages(newMessages);
            }
            setIsAgentResponding(false);

        }).catch((error) => {
            // Modal.error({ content: error.message });
            // setIsAgentResponding(false);
            console.log(error);
            if ((error.response && error.response.status === 500
                    && error.response.data?.error?.includes("parse output"))
                || error.code === "ERR_NETWORK") {
                setWaitForMessage(true);
            }
        });
    }

    useEffect(() => {
        if(!clearChat || !setClearChat){
            return;
        }

        setMessages([]);
        setHasMessages(false);
        clearChatHistoryFromLocalStorage(agentId);

        setClearChat(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clearChat]);

    useEffect(() => {
        if (defaultQuestion) {
            sendMessage(defaultQuestion);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultQuestion]);
    
    // Handle keyboard shortcuts to send a message
    const textareaSendMessage = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if(!e.shiftKey) {
            e.preventDefault();
            sendMessage();
        }
    }

    // Fetch message history
    useEffect(() => {
        if(session){
            apiRetrieveMessageHistory().then((response) => {
                if(response.data.history.length > 0){
                    setHasMessages(true);
                }
    
                setMessages(response.data.history);
                setIsLoading(false);
    
            }).catch((error) => {
                Modal.error({ content: error.message });
                setIsLoading(false);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session]);

    // In case of timeout or parse out error
    // loop fetching the message history every second
    // until a new message is received
    useEffect(() => {
        let isMounted = true; // To prevent state update if the component is unmounted

        const fetchMessageHistory = () => {
            if (!waitForMessage) return;

            apiRetrieveMessageHistory().then((response) => {
                if (!isMounted) return;

                if (response.data.history.length > messages.length) {
                    setMessages(response.data.history);
                    setWaitForMessage(false);
                    setIsAgentResponding(false);
                } else {
                    // Wait for 1 second before trying again
                    setTimeout(fetchMessageHistory, 1000);
                }
            }).catch((error) => {
                if (!isMounted) return;
                Modal.error({ content: error.message });
            });
        };

        fetchMessageHistory();

        // Cleanup function to set isMounted to false when component unmounts
        return () => {
            isMounted = false;
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [waitForMessage, messages.length]);

    useEffect(() => {
        scrollToBottom();
    }, [messages, isAgentResponding]);

    return (
        <div className={css.chat_agent}>
            <div className={css.chat_agent_wrapper}>
                {!hasMessages && (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                        isLoading ? <LoadingOutlined style={{fontSize: '1.5rem'}}></LoadingOutlined> : 'Message History Empty'
                    }/>
                )}

                <div className={css.chat_agent_output_wrapper}>
                    {messages.map((message, i) => {
                        return (message.type === agentMessageType) ?
                            (<Markdown className={css.chat_agent_output_agent}>{message.content}</Markdown>) :
                            <div className={css.chat_agent_output_user}>{message.content}</div>
                    })}

                    {isAgentResponding && (
                        <div className={css.chat_agent_output_agent}>
                            <div className={css.dot_flashing}></div>
                        </div>
                    )}

                    <div ref={msgEndRef}></div>
                </div>
            </div>
        
            <div className={css.chat_agent_input_wrapper + ' ant-input-wrapper ant-input-group'}>
                <Input.TextArea
                    placeholder="Your message"
                    autoSize={{ minRows: 2, maxRows: 4 }}
                    value={userMessage}
                    onChange={(e) => setUserMessage(e.target.value)}
                    onPressEnter={(e) => textareaSendMessage(e)}
                    className={css.chat_agent_input + ' pendo_input_ai_chat'}
                />
                <div className={css.chat_agent_input_btn_wrapper + ' ant-input-group-addon'}>
                    <Button className={css.chat_agent_input_btn + ' pendo_send_ai_chat'} onClick={sendMessage}
                        disabled={isSendMessageBlocked()} icon={<SendOutlined/>}></Button>
                </div>
            </div>
        </div>
    );
};