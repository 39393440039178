export const fetchGQL = async (method: string, query: string, variables: any) => {
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_AWS_APPSYNC_ENDPOINT || '', {
      method,
      headers: {
        'x-api-key': process.env.REACT_APP_AWS_APPSYNC_PUBLIC_APIKEY || '',
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query,
        variables,
      }),
      credentials: 'omit',
    })
      .then(function (response) {
        resolve(response.json());
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
