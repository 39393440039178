import React, { useState } from 'react';
import SlackContext, { SlackUsersExists } from './context';

type ProviderProps = {
  children?: React.ReactNode;
};

function SlackProvider(props: ProviderProps) {
  const [slackUsers, setSlackUsers] = useState<SlackUsersExists[]>([]);

  const updateSlackUsers = (usersData: SlackUsersExists[]): void => {
    setSlackUsers(usersData);
  };

  return (
    <SlackContext.Provider
      value={{
        slackUsers,
        updateSlackUsers: updateSlackUsers,
      }}
    >
      {props.children}
    </SlackContext.Provider>
  );
}

export default SlackProvider;
