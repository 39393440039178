import { Card, Col, Layout, Row, Skeleton } from 'antd';
import css from '../styles.module.css';

const LoginSkeleton = (): JSX.Element => (
  <Layout className={css.bg_login_page_skeleton}>
    <Row className={css.generic_flex_fit}>
      <Col xs={2} sm={5} md={7} lg={8} xl={9} xxl={9} />
      <Col xs={20} sm={14} md={10} lg={8} xl={6} xxl={6} style={{ textAlign: 'center' }}>
        <Skeleton.Input style={{ width: 150, marginBottom: 24 }} active />
        <Card className={css.login_card_skeleton}>
          <Skeleton.Input active style={{ height: 32, width: 150, marginBottom: 42 }} />
          <br />
          <div
            style={{
              display: 'flex',
              marginBottom: 24,
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Skeleton.Input active style={{ height: 14, width: 112, marginLeft: '-100%' }} />
            <Skeleton.Input active style={{ height: 40, width: 240, marginBottom: 14 }} />
            <Skeleton.Input active style={{ height: 14, width: 112, marginLeft: '-100%' }} />
            <Skeleton.Input active style={{ height: 40, width: 240, marginBottom: 14 }} />
            <Skeleton.Input active style={{ height: 16, width: 240 }} />
          </div>
          <Skeleton.Button active style={{ height: 40, width: 240, marginTop: 14 }} />
          <hr style={{ opacity: 0.1, margin: 14 }} />
          <Skeleton.Button active style={{ height: 40, width: 240 }} />
        </Card>
        <Skeleton.Input active style={{ height: 16, width: 240, margin: 12 }} />
      </Col>
    </Row>
  </Layout>
);

export default LoginSkeleton;
