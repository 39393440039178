import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthContext from 'context/auth/context';

function PrivateRoute({ component, fallback, ...rest }: any) {
    const auth = useContext(AuthContext);

    return (
        <>
            {
                auth.loading
                    ? fallback
                    : <Route
                        {...rest}
                        component={
                            auth.isAuthenticated
                                ? component
                                : ({ location }: any) => <Redirect to={{
                                    pathname: "/login",
                                    state: {
                                        from: location
                                    }
                                }} />
                        }
                    />
            }
        </>
    )
}

export default PrivateRoute;