import React from 'react';
import { Layout, Skeleton } from 'antd';
import css from './styles.module.css';

export default function PageTitleSkeleton() {
  return (
    <Layout.Header style={{ backgroundColor: '#f0f2f5' }}>
      <div style={{ paddingTop: 14 }}>
        <Skeleton.Avatar active shape='square' />
        <Skeleton.Input className={css.header_title} style={{ width: 120 }} />
      </div>
    </Layout.Header>
  );
}
