import { Auth } from "aws-amplify";
import AppSync, { AUTH_TYPE } from 'aws-appsync';
import config from 'config';

export const initPublicClient = () => new AppSync({
    url: config.appSync.public.endpoint!,
    region: config.appSync.public.region!,
    auth: {
        type: AUTH_TYPE.API_KEY,
        apiKey: config.appSync.public.api_key!
    },
    cacheOptions: {
        addTypename: false
    },
    offlineConfig: {
      keyPrefix: 'PUBLIC_CLIENT'
    }
});

export const initPrivateClient = () => new AppSync({
      url: config.appSync.private.endpoint!,
      region: config.appSync.private.region!,
      auth: {
        type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
        jwtToken: async () => 
        (await Auth.currentSession().catch(err => {}))?.getIdToken().getJwtToken() ?? ''
      },
      cacheOptions: {
        addTypename: false
      },
      offlineConfig: {
        keyPrefix: 'PRIVATE_CLIENT'
      },
      disableOffline: true
});

export const initDatamartClient = () => new AppSync({
  url: config.appSync.dataMart.endpoint!,
  region: config.appSync.dataMart.region!,
  auth: {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () => 
      (await Auth.currentSession().catch(err => {}))?.getIdToken().getJwtToken() ?? ''
  },
  cacheOptions: {
    addTypename: false
  },
  offlineConfig: {
    keyPrefix: 'DM_CLIENT'
  },
  disableOffline: true
});