import React, { Dispatch, SetStateAction } from 'react';
import { CognitoUser, UserProfile, Migrated } from 'models/User';
import { UserSession } from 'context/auth/provider';

export type ProfileContext = {
    profile?: UserProfile;
    migrated?: Migrated;
};

export type AuthContextType = {
    isAuthenticated: boolean,
    user?: CognitoUser,
    loading: boolean,
    setStatus: Dispatch<SetStateAction<boolean>>,
    setUser: Dispatch<SetStateAction<CognitoUser|undefined>>,
    userSession: UserSession | undefined
}

const AuthContext = React.createContext<AuthContextType>({
    isAuthenticated: false,
    loading: true,
    setStatus: () => {},
    setUser: () => {},
    userSession: undefined
});

export default AuthContext;