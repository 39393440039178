import { Row, Col, Skeleton, Space, Card } from 'antd';

export const FunctionalAreaSkeleton = ({ fullPage = true }: { fullPage?: boolean }) => {
  return (
    <>
      {fullPage && (
        <Row>
          <Col lg={12}>
            <Space>
              <Skeleton.Input style={{ width: 450 }} active={true} size='default' />
            </Space>
          </Col>
          <Col lg={12}>
            <Space>
              <Skeleton.Input style={{ width: 450 }} active={true} size='default' />
            </Space>
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={24} lg={fullPage ? 17 : 24} style={{ marginTop: fullPage ? '3rem' : 0 }}>
          <Card>
            <Skeleton active paragraph={{ rows: 10 }} />
          </Card>
          <Card style={{ marginTop: 30 }}>
            <Skeleton active paragraph={{ rows: 10 }} />
          </Card>
        </Col>
        {fullPage && (
          <Col xs={24} lg={6}>
            <div className='fast-facts-container' style={{ margin: '50px 20px' }}>
              <Row justify='center'>
                <Col span={24}>
                  <Skeleton active paragraph={{ rows: 1 }} />
                </Col>
                <Col span={23}>
                  <Skeleton active paragraph={{ rows: 1 }} />
                  <Row className='date-box' style={{ backgroundColor: '#ffffff' }}>
                    <Col span={12}>
                      <Skeleton active paragraph={{ rows: 2 }} />
                    </Col>
                    <Col span={12}>
                      <Skeleton active paragraph={{ rows: 2 }} />
                    </Col>
                  </Row>
                  <Row className='recruitment-cycle__container' style={{ marginBottom: 50 }}>
                    <Skeleton active paragraph={{ rows: 2 }} />
                  </Row>
                  <Row className='recruitment-cycle__container'>
                    <Skeleton active paragraph={{ rows: 1 }} />
                  </Row>
                </Col>
                <Col className='dates-deadlines__container' span={23}>
                  <Skeleton active paragraph={{ rows: 5 }} />
                </Col>
              </Row>
            </div>
          </Col>
        )}
      </Row>
    </>
  );
};
