import { Component } from 'react';
import * as Sentry from '@sentry/react';
import { ErrorScreen } from './errorScreen';
import React from 'react';

type State = {
  error: any;
  detail: any;
};

type Props = {
  fallback?: JSX.Element;
  onError?: (error: any, detail: any) => {};
  children?: any;
};

export default class ErrorBoundary extends Component<Props, State> {
  state = {
    error: null,
    detail: null,
  }

  componentDidCatch(error: any, detail: any) {
    const { onError } = this.props;
    this.setState({ error, detail }, () => {
      if (onError) onError(error, detail);
      Sentry.captureException(error);
    });
  }

  componentDidUpdate(prevProps: Props) {
    const childNow = React.Children.only(this.props.children);
    const childPrev = React.Children.only(prevProps.children);

    if (childNow !== childPrev) {
      this.setState({ error: null });
    }
  }

  render() {
    const { error } = this.state;
    const { children, fallback } = this.props;

    if (error) {
      if (fallback) {
        return fallback;
      } else {
        return <ErrorScreen />;
      }
    }

    return children;
  }
}
