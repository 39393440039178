import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import useAuthorizer, { AuthorizerResponse } from 'hooks/useAuthorizer';

export const BASE_URL = 'https://home.noodle.com'

export const OAUTH = '?option=oauthredirect&app_name=Noodle+Home+Okta'

export const Routes = {
    mission: 'mission-vision-and-values',
    management: 'thoughts-on-management',
    dei: 'dei',
    ergs: 'ergs-overview',
    office: 'office-co-working-info',
    faqs: 'faq',
};

export default function IframedSite({ isWikiLoaded }: { isWikiLoaded: boolean }) {
    const location = useLocation();
    const [iframeSrc, setIframeSrc] = useState<string>('');
    const { policiesLoading, isAllowed, getAnAuthorizedPage } = useAuthorizer<AuthorizerResponse>();
    const historyH = useHistory();

    useEffect(() => {
        if (!policiesLoading) {
          if (!isAllowed('/wiki')) {
            historyH.replace(getAnAuthorizedPage());
          }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [historyH, isAllowed, policiesLoading]);

    useEffect(() => {
        if (isWikiLoaded) {
            let path = location.pathname.replace('/wiki', '');
            let newIframeSrc = BASE_URL + path;

            if (iframeSrc !== newIframeSrc) {
                setIframeSrc(newIframeSrc);
            }
        }
    }, [isWikiLoaded, location]);

    return (
    <>
      {isWikiLoaded && <iframe
        src={iframeSrc}
        style={{border:'none'}}
        width="100%"
        height="100%"
      />}
    </>
    );
}