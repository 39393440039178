import React, { useState, useEffect } from 'react';
import AuthContext from './context';
import { CognitoUser } from 'models/User';
import { initUserback } from 'utils/userback';
import { Auth } from 'aws-amplify';

type ProviderProps = {
  children?: React.ReactNode;
};

export interface UserSession {
  username: string;
  idToken: string;
  accessToken: string;
  refreshToken: string;
  expires: {
    idToken: number,
    accessToken: number
  };
}

function AuthProvider(props: ProviderProps) {
  const [isAuthenticated, setStatus] = useState(false);
  const [user, setUser] = useState<CognitoUser | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [userSession, setUserSession] = useState<UserSession | undefined>();

  const setSession = () => {
    Auth.currentSession().then(
      (data) => {
        const idToken = data.getIdToken();
        const accessToken = data.getAccessToken();

        setUserSession({
          username: idToken.payload['email'],
          idToken: idToken.getJwtToken(),
          accessToken: accessToken.getJwtToken(),
          refreshToken: data.getRefreshToken().getToken(),
          expires: {
            idToken: idToken.payload['exp'],
            accessToken: accessToken.payload['exp']
          }
        });
      }
    );
  }

  useEffect(() => {
    if (!userSession) return;

    const unixtime = Math.floor(Date.now() / 1000);
    const idTokenExpiresIn = userSession.expires.idToken - unixtime;
    const accessTokenExpiresIn = userSession.expires.accessToken - unixtime;

    const expiresIn = accessTokenExpiresIn > idTokenExpiresIn ? idTokenExpiresIn : accessTokenExpiresIn;

    if (expiresIn > 0) {
      const timeout = setTimeout(() => {
        setSession();
      }, expiresIn * 1000);

      return () => clearTimeout(timeout);
    }
  }, [userSession]);

  useEffect(() => {
    if (user) {
      window.localStorage.setItem('user', JSON.stringify(user));
      setStatus(true);
      window.gtag('set', 'userID', user.sub);
      initUserback();
    } else {
      let localUser = window.localStorage.getItem('user');
      if (localUser && localUser !== 'null') {
        const usr = JSON.parse(localUser);
        setUser(usr);
        window.gtag('set', 'userID', usr?.sub);
        initUserback();
        // setStatus(true);
      }
    }
    setLoading(false);
  }, [user]);

  useEffect(() => {
    if (isAuthenticated && !userSession) {
      setSession();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        setStatus,
        setUser,
        userSession
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
