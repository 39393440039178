import { Col, Row, Skeleton } from 'antd';

export const AccordionSkeleton = (): JSX.Element => {
  const elements = [...Array(10)].map((_, i) => i + 1);
  return (
    <>
      {elements.map((_item, index) => (
        <div key={index}>
          <Row style={{ height: '82px', padding: '16px', background: '#ffffff' }}>
            <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
              <Row>
                <Skeleton.Input style={{ width: 450, height: 22 }} active />
              </Row>
              <Row>
                <Skeleton.Input style={{ width: 250, marginTop: 6, height: 22 }} active />
              </Row>
            </Col>
            <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} style={{ textAlign: 'right' }}>
              <Skeleton.Input style={{ width: 77, marginTop: 16, height: 22 }} active />
            </Col>
            <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} style={{ textAlign: 'right' }}>
              <Skeleton.Input style={{ width: 77, marginTop: 16, height: 22 }} active />
            </Col>
          </Row>
          {index !== elements.length - 1 && <div style={{ width: '100%', height: 10 }} />}
        </div>
      ))}
    </>
  );
};
