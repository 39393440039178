import React, { useState, useEffect, ReactNode } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import {AWSAppSyncClient } from 'aws-appsync';

export type RehydratedProps = {
    appSyncClient: AWSAppSyncClient<NormalizedCacheObject>,
    children: ReactNode,
    fallback?: JSX.Element|React.ReactElement
};

const Rehydrated: React.FC<RehydratedProps> = ({ children, appSyncClient, fallback }): JSX.Element => {
    const client = useApolloClient();
    const [rehydrated, setRehydrated] = useState(false);
  
    useEffect(() => {
      (async () => {
        await appSyncClient.hydrated();
        setRehydrated(true);
      })();
    }, [client, appSyncClient]);
    
    return (
      <div>
        {rehydrated ? children : fallback ? fallback : <></>}
      </div>
    );
  };

export default Rehydrated;