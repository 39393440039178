// TODO: move to env var
const token = '40150|80285|hIwfrqJfm9Tuum079UZpeQQkx';

declare global {
  interface Window {
    userBackConfig: any;
  }
}

export function checkUserback() {
  return window.Userback && Object.keys(window.Userback).length > 0 && !window.Userback.isLoaded();
}

export function initUserback() {
  if (checkUserback()) {
    window.Userback.access_token = token;
    window.Userback.init(token);
  }
}

export function hideUserback() {
  if (checkUserback()) {
    window.Userback.hide();
  }
}
