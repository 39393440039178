import React, { Suspense, useState, useEffect, useContext } from 'react';
import Skeleton from './components/skeleton';
import { useHistory, useLocation } from 'react-router-dom';
import AuthContext from 'context/auth/context';
import { ApolloProvider } from 'react-apollo';
import Rehydrated from 'components/Rehydrated';
import { initPublicClient } from 'appsync/service';

const RegularLogin = React.lazy(() => import('./components/regular'));
const LoginHandler = React.lazy(() => import('./loginHandler'));

const publicClient = initPublicClient();

declare global {
  interface Window {
    dataLayer: any;
    gtag: any;
    pendo: any;
  }
}

const LoginBoxed: React.FC = (): JSX.Element => {
  const [load, setLoad] = useState<boolean>(true);
  const [subdomain, setSubdomain] = useState<string>('');

  const history = useHistory();
  const location = useLocation();
  const auth = useContext(AuthContext);

  if (auth.isAuthenticated) {
    /*
     *  redirectUrl is the one the user visited before being sent
     *  to the login screen (no session detected)
     */
    let redirectUrl = '/';
    if (location.state && (location.state as any).from && (location.state as any).from.pathname) {
      const { from } = location.state as any;
      redirectUrl = `${from.pathname}${from.hash}`;
      if (redirectUrl.toLowerCase().includes('logout')) redirectUrl = '/';
    }
    history.push(redirectUrl);
  }

  useEffect(() => {
    (async () => {
      const sub = new URL(window.location.href).hostname.split('.')[0];
      // Avoiding localhost parsing (for local environments)
      if (sub !== 'localhost') setSubdomain(sub);
      setLoad(false);
    })();
  }, []);

  if (load) return <Skeleton />;

  return (
    <Suspense fallback={<Skeleton />}>
      <ApolloProvider client={publicClient}>
        <Rehydrated appSyncClient={publicClient} fallback={<Skeleton />}>
          {
            /*
             *  We avoid to perform query for university
             *  configuration if there is no subdomain
             */
            subdomain === '' ? <RegularLogin /> : <LoginHandler subdomain={subdomain} />
          }
        </Rehydrated>
      </ApolloProvider>
    </Suspense>
  );
};

export default LoginBoxed;
