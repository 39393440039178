import { useContext, useEffect } from 'react';
import AuthContext from 'context/auth/context';
import dayjs from 'dayjs';
type AppLogoutProps = {
  children: JSX.Element;
  onLogout: () => void;
  isLogoutTimerStarted: boolean;
};

const AutoLogout = ({ children, onLogout, isLogoutTimerStarted }: AppLogoutProps) => {
  let timer: NodeJS.Timeout | null = null;
  const events = ['mousemove', 'mousedown', 'click', 'scroll', 'keypress'];
  const { isAuthenticated } = useContext(AuthContext);
  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      resetTimer();
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      onLogout();
    }, 900000); // 900000ms = 15min
  };

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  useEffect(() => {
    if (isAuthenticated && !isLogoutTimerStarted) {
      const eventListenerCallback = () => {
        resetTimer();
        handleLogoutTimer();
        window.localStorage.setItem('lastActivity', dayjs().format());
      };

      Object.values(events).forEach((item) => {
        window.addEventListener(item, eventListenerCallback);
      });

      return () => {
        Object.values(events).forEach((item) => {
          window.removeEventListener(item, eventListenerCallback);
        });
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLogoutTimerStarted]);

  return children;
};

export default AutoLogout;
