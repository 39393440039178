import { createContext, useContext } from 'react';

// TODO: update "object" types to corresponding types when `../Detail/index.tsx` has been refactored
export const AgentsContext = createContext<{
    session: any, agent: any, 
    clearChat?: boolean, setClearChat?: Function
} | undefined>(undefined);

export function useAgentsContext(){
    const agentsContext = useContext(AgentsContext);

    if(agentsContext === undefined){
        throw new Error('useAgentsContext must be used with an AgentsContext');
    }

    return agentsContext;
}