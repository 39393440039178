const config = {
  cognito: {
    REGION: process.env.REACT_APP_AWS_REGION,
    USER_POOL_ID: process.env.REACT_APP_AWS_COGNITO_POOL,
    APP_CLIENT_ID: process.env.REACT_APP_AWS_APP_CLIENT,
  },
  appSync: {
    public: {
      region: process.env.REACT_APP_AWS_REGION,
      api_key: process.env.REACT_APP_AWS_APPSYNC_PUBLIC_APIKEY,
      endpoint: process.env.REACT_APP_AWS_APPSYNC_ENDPOINT
    },
    private: {
      region: process.env.REACT_APP_AWS_REGION,
      endpoint: process.env.REACT_APP_AWS_APPSYNC_ENDPOINT
    },
    dataMart: {
      region: process.env.REACT_APP_AWS_REGION,
      endpoint: process.env.REACT_APP_AWS_APPSYNC_DM_ENDPOINT,
      api_key: process.env.REACT_APP_AWS_APPSYNC_DM_APIKEY,
    }
  }
};

export default config;