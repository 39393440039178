import React from 'react';
import config from './config';
import ReactDOM from 'react-dom';
import Main from './screens/main';
import Login from './screens/login';
import { Amplify } from 'aws-amplify';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { createBrowserHistory } from 'history';
import PrivateRoute from 'router/PrivateRoute';
import AuthProvider from 'context/auth/provider';
// import reportWebVitals from './reportWebVitals';
import ForgotPassword from './screens/forgotPassword';
import { Router, Switch, Route } from 'react-router-dom';
import 'antd/dist/antd.css';
import FiltersProvider from 'context/filters/provider';
import SlackProvider from 'context/slack/provider';
import 'utils/global-styles.css';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.REACT_APP_SENTRY_ENV ? process.env.REACT_APP_SENTRY_ENV : 'Localhost',
    tracesSampleRate: 1.0,
  });
}

const history = createBrowserHistory();

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
});

if (window.location.href.indexOf('?code') > -1) {
  const urlStr: string = window.location.href;
  const initialStr: string | undefined = urlStr.split('?code=').pop();
  if (initialStr) {
    const result: string = initialStr.substring(0, initialStr.indexOf('&state'));
    window.localStorage.setItem('slackCode', result);
  }
}

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={<>"An error has ocurred."</>} showDialog>
    <React.StrictMode>
      <AuthProvider>
        <Router history={history}>
          <Switch>
            <Route exact path='/login' component={Login} />
            <Route exact path='/forgot-password' component={ForgotPassword} />
            {/* TODO: Make generic fallback while persisted session is being checked */}
            <FiltersProvider>
              <SlackProvider>
                <PrivateRoute fallback={<>...</>} path='/' component={Main} />
              </SlackProvider>
            </FiltersProvider>
          </Switch>
        </Router>
      </AuthProvider>
    </React.StrictMode>
  </Sentry.ErrorBoundary>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
