import React from 'react';

export type SlackContextType = {
  slackUsers: SlackUsersExists[];
  updateSlackUsers: (usersData: SlackUsersExists[]) => void;
};

export interface SlackUsersExists {
  email: string;
  exists: boolean;
  slack_id: string;
  error: {
    display_message: string;
    error: string;
    status_code: number;
  };
}

const SlackContext = React.createContext<SlackContextType>({
  slackUsers: [],
  updateSlackUsers: () => {},
});

export default SlackContext;
