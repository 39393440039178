import React from 'react';
import MainContext from './context';
import { initDatamartClient } from 'appsync/service';

type ProviderProps = {
    children?: React.ReactNode
};

const datamartClient = initDatamartClient();

function MainProvider(props: ProviderProps) {

    return (
        <MainContext.Provider value={{
            datamartClient,
        }}>
            { props.children }
        </MainContext.Provider>
    )
}

export default MainProvider;