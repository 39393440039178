import { ColumnsType } from 'antd/lib/table';
import { Skeleton, SkeletonProps, Table } from 'antd';
import { AccordionSkeleton } from './AccordionSkeleton';

export type SkeletonTableProps<T> = SkeletonProps & {
  extraKey: string;
  rowCount?: number;
  columns: ColumnsType<T>;
  paginationOnTop?: boolean;
  paginationOnBottom?: boolean;
  type?: 'accordion' | 'default';
};

export type StandaloneTableSkeletonProps = SkeletonProps & {
  extraKey: string;
  rowCount?: number;
  paginationOnTop?: boolean;
  paginationOnBottom?: boolean;
};

export function StandaloneTableSkeleton({
  extraKey,
  active = true,
  rowCount = 5,
  paginationOnTop = true,
  paginationOnBottom = true,
}: StandaloneTableSkeletonProps): JSX.Element {
  const data = [...Array(rowCount)].map((_, index) => ({
    key: `key${index}`,
  }));
  return (
    <>
      {paginationOnTop && <PaginationSkeleton />}
      <Table
        rowKey='key'
        pagination={false}
        dataSource={data}
        columns={data.map((d: any, index: number) => {
          return {
            render: function renderPlaceholder() {
              return (
                <Skeleton
                  key={`${extraKey}-${d.key}-${index}`}
                  title
                  active={active}
                  paragraph={false}
                />
              );
            },
          };
        })}
      />
      {paginationOnBottom && <PaginationSkeleton style={{ marginTop: 17 }} />}
    </>
  );
}

export function PaginationSkeleton({ style }: { style?: any }) {
  const propStyle = style ? style : {};
  return (
    <div
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', ...propStyle }}
    >
      <Skeleton.Input style={{ width: 150, marginBottom: 24 }} active />
    </div>
  );
}

export default function TableSkeleton<T>({
  extraKey,
  loading = false,
  active = false,
  rowCount = 5,
  columns,
  children,
  type,
  className,
  paginationOnTop = true,
  paginationOnBottom = true,
}: SkeletonTableProps<T>): JSX.Element {
  return loading ? (
    <>
      {paginationOnTop && <PaginationSkeleton />}
      {type === 'accordion' ? (
        <AccordionSkeleton />
      ) : (
        <Table
          rowKey='key'
          pagination={false}
          dataSource={[...Array(rowCount)].map((_, index) => ({
            key: `key${index}`,
          }))}
          columns={columns.map((column: any, index: number) => {
            return {
              ...column,
              render: function renderPlaceholder() {
                return (
                  <Skeleton
                    key={`${extraKey}-${column.key}-${index}`}
                    title
                    active={active}
                    paragraph={false}
                    className={className}
                  />
                );
              },
            };
          })}
        />
      )}
      {paginationOnBottom && <PaginationSkeleton style={{ marginTop: 17 }} />}
    </>
  ) : (
    <>{children}</>
  );
}
